<template>
  <div class="otherOrderDelivery">
    <p class="title">质检单上传</p>
    <div class="content">
      <el-row>
        <el-col :span="8">
          产品名称:
          <el-select
            class="reportName"
            v-model="reagent_id"
            placeholder="请输入产品名称"
            size="small"
            filterable
            clearable
            remote
            @change="changeRegent"
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in reagentOptions"
              :key="item.reagent_id"
              :label="item.reagent_name"
              :value="item.reagent_id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          产品批号:
          <div class="batchDiv">
            <el-input v-model="batch" placeholder="请输入产品批号"></el-input>
          </div>
        </el-col>
      </el-row>
      <el-upload
        class="upload-demo"
        drag
        ref="upload"
        action=""
        :auto-upload="false"
        :before-upload="beforeUpload"
        :http-request="handleUpload"
        :file-list="fileList"
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>

        <div class="el-upload__tip" slot="tip">
          只能上传jpg/png/jpeg/pdf文件，且单张不超过10M
        </div>
      </el-upload>
      <el-button
        style="margin-left: 10px"
        size="small"
        type="success"
        @click="check_report_batchFun"
      >
        上传到服务器
      </el-button>
      <el-button
        style="margin-left: 10px"
        size="small"
        type="danger"
        @click="delReport"
      >
        删除质检单
      </el-button>
      <!-- <el-button style="margin-left: 10px;" size="small" type="danger" @click="deleteOss">删除文件</el-button> -->
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <!-- <span>这是一段信息</span> -->
      <div>
        <p>
          当前产品名称为{{ this.reagen.reagent_name }},批号为{{
            this.batch
          }}的质检单已存在
        </p>
        <div v-if="is_pdf">
          <embed 
            id="printMe"
            :src="reportsImg[0]"
            type="application/pdf"
            width="100%"
            height="200px" />
        </div>
        <div v-else>
          <el-image
            style="width: 100px; height: 100px"
            v-for="(item, index) in reportsImg"
            :key="index"
            :src="item"
            :preview-src-list="reportsImg"
          fit="contain"
          ></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="isDel" type="danger" @click="del_reportsFun">确认删除</el-button>
        <el-button v-else type="primary" @click="coverReports">确定覆盖</el-button>

      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  put,
  del,
  signatureUrl,
  getFileNameUUID,
  getFileName,
} from '@/assets/js/oss'
import api from '@/api/other.js'
import apiDetail from '@/api/searchDetail.js'
import { find_reagent2report, searchData } from '@/api/search.js'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      batch: '',
      reagent_id: '',
      inventory_id: '',
      dialogVisible: false,
      isDel: false,
      reportsImg: [], //质检单图片列表
      reagen: '', //选中的产品数据
      testing_id: '', //检测项目标识
      testing_unique: '', //项目名称
      reagent_class_id: '', //试剂大类ID
      report_type: '', //试剂类别
      nowFileNum: 0,
      fileList: [],
      reagentOptions: [],
      uploadFilesLength: 1, //待传图片数
      loading: '', //全局加载效果
      is_pdf: false,
    }
  },
  methods: {
    remoteMethod(reagent_name) {
      let params = { reagent_name, size: 'all' }
      find_reagent2report(params).then((res) => {
        this.reagentOptions = res.data
      })
    },
    changeRegent(reagent_id) {

      let reagen = this.reagentOptions.filter(
        (e) => e.reagent_id == reagent_id,
      )[0]
      this.reagen = reagen
      this.testing_id = reagen.testing_id
      this.testing_unique = reagen.testing_unique
      this.reagent_class_id = reagen.reagent_class_id
      this.report_type = reagen.report_type
    },
    beforeUpload(file) {
      // 限制上传类型
      const fileExtensions =
        getFileName(file.name) === '.jpg' ||
        getFileName(file.name) === '.jpeg' ||
        getFileName(file.name) === '.pdf' ||
        getFileName(file.name) === '.png'
      //限制的上限为20M
      const max20M = file.size / 1024 / 1024 < 10
      if (!fileExtensions) {
        this.$message.error('上传文件类型只能是 .jpg, .jpeg, .png, .pdf 格式!')
        this.loading.close()

      }
      if (!max20M) {
        this.$message.error('上传文件大小不能超过 10MB!')
        this.loading.close()
      }
      
      return fileExtensions && max20M
    },
    /**
     * 自定义上传方法
     */
    handleUpload(option) {
      let suffix = getFileName(option.file.name) // 获取文件的后缀名
      //                      项目                          试剂大类ID                批号             试剂类别                               标号
      let objName = `/media/report/${this.testing_unique}/${
        this.reagent_class_id
      }/${this.batch}/${this.report_type.report_type_name}/${++this.nowFileNum}${suffix}`
      // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      put(objName, option.file).then((res) => {
        if (this.nowFileNum == this.uploadFilesLength) {
          Message.success('上传成功')
          this.nowFileNum = 0
          this.fileList = []
          this.dialogVisible = false
          this.loading.close()
        }
        // 上传成功之后，转换真实的地址
      })
    },
    // 查看质检单
    reportsFun() {
      searchData('reports', {
        page: 1,
        size: 10,
        reagent_class_id: this.reagen.reagent_class_id,
        report_type: this.reagen.report_type.report_type_id,
        batch: this.batch,
      }).then((res) => {
        if (res.code == 200) {
          if(res.data.data.length == 0){
            Message.success('当前批号下无质检单')
            this.dialogVisible = false;
            this.isDel = false;
            return
          }
          this.report_id = res.data.data[0].report_id
          this.reportsImg = res.data.data[0].report_img
          this.is_pdf = res.data.data[0].is_pdf
        }
      })
    },
    // 查询当前试剂和批号是否包含在现有批号内
    check_report_batchFun() {
      this.uploadFilesLength = this.$refs.upload.uploadFiles.length
      if (this.uploadFilesLength == 0) return Message.error('请先选择图片')
      if (this.reagent_class_id == '' && this.batch == '')
        return Message.error('请先输入产品名称和批号')
      let params = {
        reagent_class_id: this.reagent_class_id,
        testing_id: this.testing_id,
        report_type: this.report_type.report_type_id,
        batch: this.batch,
      }
      api.check_report_batch(params).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            //新增
            this.submitUpload()
          } else {
            //已存在
            this.dialogVisible = true
            this.reportsFun()
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    coverReports() {
      // 删除之前的
      let oss_path = `media/report/${this.testing_unique}/${this.reagent_class_id}/${this.batch}/${this.report_type.report_type_name}`
      api.delete_oss_file({ oss_path }).then((res) => {
        if (res.code == 200) {
          this.submitUpload()
        }
      })
    },
    // 删除质检单
    del_reportsFun() {
      api.del_reports({ report_id:this.report_id }).then((res) => {
        if (res.code == 200) {
          Message.success('删除成功')
          this.dialogVisible = false;
          this.isDel = false;
        }
      })
    },
    // 开启删除
    delReport() {
      if (this.reagent_class_id == '' && this.batch == '')
        return Message.error('请先输入产品名称和批号')

      this.dialogVisible = true;
      this.isDel = true;
      this.reportsFun();
    },
    submitUpload() {
      this.$refs.upload.submit()

      this.loading = this.$loading({
        lock: true,
        text: '正在上传',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.otherOrderDelivery {
  margin: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
  }
  .content {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    .el-row {
      margin-bottom: 20px;
      .batchDiv {
        display: inline-block;
        margin-left: 3px;
        ::v-deep .el-input,
        ::v-deep .el-input__inner {
          height: px(43);
        }
      }
    }
  }
}
.reportName {
  ::v-deep .el-input__inner {
    width: px(400);
  }
}
</style>